.filipos{
    margin: 50px;
    transform: rotate(270deg);
    width:400px;
    height:400px;
    }
    
    html,body {
        background-color: white;
    
            color: white;
            text-align:center;
      }
      .grid{
        margin: 0;
        display: grid;
        grid-template-columns: auto 85%;
        grid-template-rows: 8% auto 15%; 
        grid-template-areas:
        "Sidebar" 
        " header"
         "main"
         "footer" ;
      }
      .SideNav{
        grid-area: sidebar;
      }
      .main{
        grid-area: main;
      }
      .footer{
        grid-area: footer;
      }
      .header{
        grid-area: header;
      }
      h1{
        font-family: Arial, Helvetica, sans-serif ;
        text-align:center;
        color: black;
      }
      .zmena{
        display: none;
      }
    
      .palma{
        width:200px;
        height:200px;
        margin: 100px;
      }
    
      #pravo {
        height: 100%; /* Full-height: remove this if you want "auto" height */
        width: 160px; /* Set the width of the sidebar */
        position: fixed; /* Fixed Sidebar (stay in place on scroll) */
        top: 0; /* Stay at the top */
        right: 0;
        background-color: white; /* Black */
        overflow-x: hidden; /* Disable horizontal scroll */
        padding-top: 20px;
      }