@font-face {
  font-family: 'Kanit';
  font-style: normal;
  font-weight: 200;
  src: url('fonts/Kanit-ExtraLight.ttf') format('truetype');
}
@viewport {
  width: device-width;
  height: device-height;
  /* Change the value of "safe" to "auto" if you don't want to inset the content */
  /* Change the value of "env" to "px" if you want to use pixels instead of environment units */
  padding: env(safe-area-inset);
}


* {
  font-family: 'Kanit', sans-serif;
  font-size: 22px;
}

h1{
  font-size: 40px;
}

.levo{
  text-align: left;
}

@media (max-width: 767px) {
  .levo{
    text-align: justify;
  }
  }

p{
  margin-left: 2%;
  margin-right: 2%;

}

@media (max-width: 767px) {
  .long{
    text-align: justify;
  }
}



html, body {
  background-color: white;
  color: white;
  text-align: center;
  font-family: 'Kanit';
  font-weight: 100;
}

.header {
  position: relative;
  height: auto;
  font-family: 'Kanit';
  font-weight: 100;
}

table {
  border-collapse: collapse;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  color: #333;
}

th, td {
  padding: 12px;
  text-align: left;
}

.contain{
  max-width: 400px;
  max-height: 200px;
  object-fit: cover;
}

.logo{
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

th {
  background-color:rgb(199, 189, 255);
  font-weight: bold;
}

tr:nth-child(even) {
  background-color:  #ebebeb;
}

table + table {margin-top:50px;}

/* Additional styling */
table th:first-child,
table td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

table th:last-child,
table td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

#pls{
  position: relative;
  width: auto;
  overflow:hidden;
}

 @media (min-width: 768px) {
  #pls{
  height:400px; 
}
  }

#obecne{
  text-align: left;
}

.header-image {
  position: center bottom;
  vertical-align: bottom;
    display: table-cell;
  /* Set the max width to be 100% of the header */
  max-width: 100%;
  /* Set the height to auto to maintain aspect ratio */
  max-height: 100%;
  object-fit: cover;
  overflow: hidden;
}


.main {
  position: relative; /* Add relative positioning for pseudo-elements */
  vertical-align: top;
  padding-left: 5%;
  padding-right: 5%;
}



.Navbar {
  align-items: center;
}

.Navbar ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding:  0;
  background-color: #eee;
  opacity: 0.8;
  overflow-x: hidden; /* Hide any horizontal overflow */
}
 @media (max-width: 767px) {
  .Navbar ul {
    display: flex;
    flex-direction: column-reverse;
    align-items: stretch;
    padding: 0.5rem;
    font-size:10;
    }
  }
 @media (max-width: 767px) {
    .Navbar li {
      margin-left: auto;
      margin-right: auto;
    border-radius: 5px;
    border-color: #333;
  }
}


.Navbar a {
  text-decoration: none;
  color: #444;
  padding: 1rem;
  transition: background-color 0.2s ease;
}
@media (max-width: 767px) {
  .Navbar a {
    flex-direction: column;
    align-items: stretch;
    padding: 0.5rem;
    font-size:10;
    }
  }

  .grid-flag {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    padding: 0%;
  }

  .flag-item {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 5%;
  }
  
 .flag {
  width: 80px; /* Adjust the width as needed */
  height: auto;
   opacity: 1;
   display: grid;
   place-items: center;
   align-items: center;
  } 
  @media (max-width: 767px) {
    .flag {
      flex-direction: column;
      align-items: stretch;
      size:50px;
      opacity: 1;
      }
    }
  

.grid {
  margin: 0;
  min-height: 100vh;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: 50px 1fr 30px;
  grid-template-rows: min-content auto 300px;
  grid-template-areas:
    "header"
    "main"
    "footer";
}


.googlemap {
  width:500px;
  height:500px;
  border-radius: 5px;
  border-color: black;
}

 @media (max-width: 767px) {
  .googlemap {
    width:300px;
    height:300px;
    }
  }

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  padding: 5%;
}

  @media (max-width: 767px) {
    .grid-container {
    display:flex;
    flex-direction: column;
    align-items: stretch;
    padding: 1rem;
    }
  }
.grid-onas-fakt {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 100px;
  padding: 5%;
}

 @media (max-width: 767px) {
  .grid-onas-fakt {
    display:flex;
    flex-direction: column;
    align-items: stretch;
    padding: 1rem;
    }
  }

.grid-onas-okolo {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 60px;
  padding: 5%;
}

  @media (max-width: 767px) {
    .grid-onas-okolo {
    display:flex;
    flex-direction: column;
    align-items: stretch;
    padding: 1rem;
    }
  }

.grid-item {
  display: flex;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  padding: 5%;
}

.grid-item img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.footer {
  -ms-grid-row: 3;
  -ms-grid-column: 2;
  grid-area: footer;
  background-color:rgb(255, 226, 222);
 
}

.header {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: header;
}

h1 {
  font-family: 'Kanit';
  text-align: center;
  color: black;
}

.inline-block-child {
  display: inline-block;/* aby footer byl side byl horizontálně */
  margin: 0;
  vertical-align: middle;
}

.line {
  display: 'flex';
  vertical-align: middle;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: 'row';
          flex-direction: 'row';
}

.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  cursor: pointer;
}

.fullscreen-image-container {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}

.fullscreen-image-container img {
  max-width: 80vw;
  max-height: 80vh;
  width: 'auto';
  height: 'auto';
  border: 3px solid #000;
  border-radius: 20px;
}


.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
}

@media (max-width: 767px) {
  .grid-container {
  display:flex;
  flex-direction: column;
  align-items: stretch;
  padding: 1rem;
  }
}

.grid-item img {
  width: 100%;
  height: auto;
  cursor: pointer;
}